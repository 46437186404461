import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import store from '@/store/vuex';
import axios from "axios";
import axiosInstance from "@/service/axiosInstance";

const isAuthenticated = store.getters.isAuthenticated;

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/dashboard',
            component: AppLayout,
            meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin', 'user'] },
            children: [
                {
                    path: '/dashboard',
                    name: 'dashboard',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin', 'user'] },
                    component: () => import('@/views/Dashboard.vue')
                },
                {
                    path: '/event/alerteventmessage/:event_seq',
                    name: 'alerteventmessage',
                    component: () => import('@/views/event/AlertEventMessage.vue')
                },
                {
                    path: '/syopt/enterprisejoin',
                    name: 'enterprisejoin',
                    component: () => import('@/views/syopt/EnterpriseJoin.vue')
                },
                {
                    path: '/syopt/enterpriselist',
                    name: 'enterpriselist',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner'] },
                    component: () => import('@/views/syopt/EnterpriseList.vue')
                },
                {
                    path: '/syopt/enterprisesmslist',
                    name: 'enterprisesmslist',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner'] },
                    component: () => import('@/views/syopt/EnterpriseSmsList.vue')
                },
                {
                    path: '/syopt/certificationCameraList.vue',
                    name: 'certificationCameraList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/syopt/CertificationCameraList.vue')
                },
                {
                    path: '/syopt/enterpriseView/:seq',
                    name: 'enterpriseView',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner'] },
                    component: () => import('@/views/syopt/EnterpriseView.vue')
                },
                {
                    path: '/syopt/enterpriseModify/:seq',
                    name: 'enterpriseModify',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner'] },
                    component: () => import('@/views/syopt/EnterpriseModify.vue')
                },
                {
                    path: '/event/PcAlertEventList',
                    name: 'PcAlertEventList',
                    component: () => import('@/views/event/PcAlertEventList.vue')
                },
                {
                    path: '/event/MobileAlertEventList',
                    name: 'MobileAlertEventList',
                    component: () => import('@/views/event/MobileAlertEventList.vue')
                },
                {
                    path: '/user/userJoin',
                    name: 'userJoin',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/user/UserJoin.vue')
                },
                {
                    path: '/user/userList',
                    name: 'userList',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/user/UserList.vue')
                },
                {
                    path: '/user/userView/:seq',
                    name: 'userView',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/user/UserView.vue')
                },
                {
                    path: '/user/userModify/:seq',
                    name: 'userModify',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/user/UserModify.vue')
                },
                {
                    path: '/serialKey/SerialKeyJoin/:original_serial_key',
                    name: 'serialKeyJoin',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/serialKey/SerialKeyJoin.vue'),
                },
                {
                    path: '/works/SYMON-FDSDownload',
                    name: 'SYMON-FDSDownload',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin', 'user'] },
                    component: () => import('@/views/works/SYMON-FDSDownload.vue'),
                },
                {
                    path: '/works/manual/ManualUserView/:manual_name',
                    name: 'ManualUserView.vue',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin', 'user'] },
                    component: () => import('@/views/works/manual/ManualUserView.vue'),
                },
                {
                    path: '/works/ProgramList',
                    name: 'ProgramList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/ProgramList.vue'),
                },
                {
                    path: '/works/ProgramJoin',
                    name: 'ProgramJoin',
                    meta: { requiresAuth: true, roles: ['syopt'], purpose: 'join' },
                    component: () => import('@/views/works/ProgramJoin.vue'),
                },

                {
                    path: '/works/ProgramResourceList/:program_name',
                    name: 'ProgramResourceList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/ProgramResourceList.vue'),
                },

                {
                    path: '/works/ProgramResourceView/:program_name/:seq',
                    name: 'ProgramResourceView',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/ProgramResourceView.vue'),
                },
                {
                    path: '/works/ProgramResourceJoin/:program_name',
                    name: 'ProgramResourceJoin',
                    meta: { requiresAuth: true, roles: ['syopt'], purpose: 'join' },
                    component: () => import('@/views/works/ProgramResourceJoin.vue'),
                },
                {
                    path: '/works/ProgramResourceModify/:program_name/:seq',
                    name: 'ProgramResourceModify',
                    meta: { requiresAuth: true, roles: ['syopt'], purpose: 'modify' },
                    component: () => import('@/views/works/ProgramResourceJoin.vue'),
                },
                {
                    path: '/works/manual/ManualList',
                    name: 'ManualList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/manual/ManualList.vue'),
                },
                {
                    path: '/works/manual/ManualVersionList/:manual_name',
                    name: 'ManualVersionList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/manual/ManualVersionList.vue'),
                },
                {
                    path: '/works/manual/ManualModify/:manual_name/:version',
                    name: 'ManualModify',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/manual/ManualModify.vue'),
                },
                {
                    path: '/serialKey/SerialKeyList',
                    name: 'SerialKeyList',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/serialKey/SerialKeyList.vue'),
                },
                {
                    path: '/sms/smsUserList/:seq',
                    name: 'SmsUserList',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/sms/SmsUserList.vue'),
                },
                {
                    path: '/sms/smsUserModify/:seq',
                    name: 'smsUserModify',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/sms/SmsUserModify.vue'),
                },
                {
                    path: '/camera/cameralist',
                    name: 'CameraList',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/camera/CameraList.vue'),
                },
                {
                    path: '/camera/cameraChart/:seq',
                    name: 'CameraChart',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/camera/CameraChart.vue'),
                },
                {
                    path: '/camera/PcCameraView/:seq',
                    name: 'PcCameraView',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/camera/PcCameraView.vue'),
                },
                {
                    path: '/camera/KinesisTest',
                    name: 'KinesisTest',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/camera/KinesisTest.vue'),
                },
                {
                    path: '/camera/MobileCameraView/:seq',
                    name: 'MobileCameraView',
                    meta: { requiresAuth: true, roles: ['syopt', 'partner', 'admin'] },
                    component: () => import('@/views/camera/MobileCameraView.vue'),
                },
                {
                    path: '/works/mqttTest',
                    name: 'mqttTest',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/mqttTest.vue'),
                },
                {
                    path: '/works/customerService/CustomerServiceRequestList',
                    name: 'ServiceRequestList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/customerService/CustomerServiceRequestList.vue'),
                },
                {
                    path: '/works/NoticeList',
                    name: 'NoticeList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/NoticeList.vue'),
                },
                {
                    path: '/works/FaqList',
                    name: 'FaqList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/FaqList.vue'),
                },
                {
                    path: '/works/ResourceList/:resource_type',
                    name: 'ResourceList',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/ResourceList.vue'),
                },
                {
                    path: '/works/ResourceJoin/:resource_type',
                    name: 'ResourceJoin',
                    meta: { requiresAuth: true, roles: ['syopt'], purpose: 'join' },
                    component: () => import('@/views/works/ResourceJoin.vue'),
                },
                {
                    path: '/works/ResourceView/:resource_type/:seq',
                    name: 'ResourceView',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/works/ResourceView.vue'),
                },
                {
                    path: '/works/ResourceModify/:resource_type/:seq',
                    name: 'ResourceModify',
                    meta: { requiresAuth: true, roles: ['syopt'], purpose: 'modify' },
                    component: () => import('@/views/works/ResourceJoin.vue'),
                },
                {
                    path: '/dataDashboard/chart',
                    name: 'chart',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/dataDashboard/Chart.vue'),
                },
                {
                    path: '/dataDashboard/menu',
                    name: 'menu',
                    meta: { requiresAuth: true, roles: ['syopt'] },
                    component: () => import('@/views/dataDashboard/Menu.vue'),
                },
                {
                    path: '/swagger-ui',
                    meta: { requiresAuth: true, roles: ['syopt'] } // Swagger 페이지는 'syopt' 역할을 가진 사용자만 접근 가능
                },
                {
                    path: '/impersonate',
                    meta: { requiresAuth: true, roles: ['syopt'] }
                },
            ]
        },
        {
            path: '/auth/login',
            name: 'login',
            component: () => import('@/views/pages/auth/Login.vue')
        },
        {
            path: '/auth/error',
            name: 'error',
            component: () => import('@/views/pages/auth/Error.vue')
        },
        {
            path: '/',
            name: 'main',
            component: () => import('@/views/Main.vue')
        }
    ]
});

// Vue Router의 beforeEach 가드에서
router.beforeEach((to, from, next) => {
    // "/auth/login" 페이지 또는 특정 조건을 충족하는 경우 세션 검사를 하지 않음
    if (to.path === '/auth/login' || to.path === '/syopt/enterprisejoin' || to.path === '/' || /\/event\/alerteventmessage\/.*/.test(to.path)) {
        next();
        return;
    }

    // 세션 만료 여부 확인
    axiosInstance.get('/common/sessionCheck', {
        withCredentials: true
    }).then(response => {
        // 세션 정보가 있는 경우
        if (to.path.startsWith('/serialKey/SerialKeyJoin')) {
            // original_serial_key 값을 저장
            sessionStorage.setItem('originalSerialKey', to.params.original_serial_key);
        }
        // 모든 페이지에 대해 이전 경로 저장
        sessionStorage.setItem('preLoginRoute', to.fullPath);
        next();
    }).catch(error => {
        sessionStorage.setItem('preLoginRoute', to.fullPath);
        // 세션이 만료된 경우 또는 에러 발생 시
        alert("로그인 세션 시간이 만료되어 로그인 페이지로 이동합니다.");
        store.dispatch('logout');
        router.push({ name: 'login' });
    });
});


// 세션 만료 시간 확인을 위한 타이머
let sessionTimer;

// 라우터 이동 시마다 호출되는 함수
/*router.beforeEach((to, from, next) => {
    // "/auth/login" 페이지에서는 세션 검사를 하지 않음
    if (to.path === '/auth/login' || /\/event\/alerteventmessage\/\d+/.test(to.path)) {
        next(); // 바로 다음 라우트로 이동
        return; // 이하 코드 실행 방지
    }

    // 세션 만료 시간 확인 주기 (예: 1분마다)
    /!*const sessionCheckInterval = 600000; // 10분*!/
    /!*const sessionCheckInterval = 10000; // 10초*!/

    const sessionCheckInterval = 86410 * 1000; // 24시간 + 10초 (밀리초 단위로 변환)

    // 기존 타이머가 있다면 해제
    if (sessionTimer) {
        clearInterval(sessionTimer);
    }

    // 새로운 타이머 설정
    sessionTimer = setInterval(() => {
        // 세션 만료 여부를 확인하는 API 호출
        axiosInstance.get('/common/sessionCheck', {
          withCredentials: true
        })
            .then(response => {
                // 세션 정보가 있는 경우
                console.log('세션 유효:', response.data.userData);
            })
            .catch(error => {
                // 세션이 만료된 경우 또는 다른 에러 발생 시
                console.error('세션 만료 또는 에러:', error);

                // 세션이 만료되면 로그아웃 처리
                clearInterval(sessionTimer); // 타이머 해제
                alert("로그인 세션 시간이 만료되어 로그인페이지로 이동합니다.");
                store.dispatch('logout');
                router.push({ name: 'login' });
            });
    }, sessionCheckInterval);

    next();
});*/

router.beforeEach((to, from, next) => {

    // CKEditor 인스턴스 파괴
    if (window.CKEDITOR) {
        const ckEditorInstances = Object.values(window.CKEDITOR.instances || {});
        ckEditorInstances.forEach(instance => {
            instance.destroy(true).catch(error => console.error(error));
        });
    }

    const requiresAuth = to.meta.requiresAuth;
    const excludedPaths = [
        '/event/alerteventmessage/',
        '/syopt/enterprisejoin'
        // 여기에 추가하려는 다른 제외 경로들을 나열하세요.
    ];

    if (to.path === '/') {
        next();
    }
    else if (excludedPaths.some(path => to.path.startsWith(path))) {
        // 제외할 경로들 중 하나와 현재 경로가 일치하는 경우 권한 체크를 하지 않고 그대로 진행
        next();
    } else if (requiresAuth) {
        const userRoles = store.state.user ? [store.state.user.user_role] : [];
        const enterprise_use_yn = store.state.user ? store.state.user.enterprise_use_yn : null;

        if (!userRoles || userRoles.length === 0) {
            // 사용자가 로그인하지 않은 경우 로그인 페이지로 이동
            next('/auth/login');
        }else if (enterprise_use_yn === 'N') {
            alert("인증 대기중인 기업회원입니다.");
            next('/auth/login'); // enterprise_use_yn이 'N'인 경우
        }else if (!hasPermission(userRoles, to.meta.roles)) {
            // 사용자가 권한이 없는 페이지에 접근하는 경우 권한이 없음 페이지로 이동
            alert('권한이 없는 계정입니다.');
            next('/auth/login');
        } else {
            // 권한이 있으면 계속 진행
            next();
        }
    } else {
        // 권한 체크가 필요하지 않은 경우
        next();
    }
});

router.afterEach((to, from) => {
    if (from.path === '/auth/login') {
        sessionStorage.removeItem('originalSerialKey');
        sessionStorage.removeItem('preLoginRoute');
    }
});


function hasPermission(userRoles, requiredRoles) {
    return requiredRoles.some(role => userRoles.includes(role));
}

export default router;
